import { Component } from 'react'
import { LinkContainer } from "react-router-bootstrap"
import AppStore from '../images/appstore.svg'
import PlayStore from '../images/playstore.png'
import './main.css'

class Main extends Component {
    handleApp(app) {
        if (app === 'apple') {
            window.open(`https://apps.apple.com/us/app/gigloca/id6504925922`, '_blank', 'noopener')
        } else if (app === 'play') {
            window.open(`https://play.google.com/store/apps/details?id=com.gigloca&hl=en`, '_blank', 'noopener')
        }
    }
    render() {
        return <div className='Main-Options'>
            <div className='Main-Options-Container'>
                <div className='Main-Options-Inner'>
                    <div className='Main-Options-Text'>For an All-Access pass to Gigloca, <LinkContainer to='/signup'><span className='Main-Options-Link Main-Options-Link-Blue'>sign up</span></LinkContainer> or <LinkContainer to='/login'><span className='Main-Options-Link Main-Options-Link-Red'>log in</span></LinkContainer>!</div>
                    <div className='Main-Options-Row'>
                        <LinkContainer to='/signup'><div className='Main-Options-Button Main-Options-Button-Fill-Dark'>Sign Up</div></LinkContainer>
                    </div>
                    <div className='Main-Options-Row'>
                        <LinkContainer to='/login'><div className='Main-Options-Button Main-Options-Button-Fill'>Log In</div></LinkContainer>
                    </div>
                    <div className='Main-Options-Row'>
                        <LinkContainer to='/'><div className='Main-Options-Button Main-Options-Button-Skip'>Skip to Home Page</div></LinkContainer>
                    </div>
                </div>
                <div className='Main-Options-Row Main-Options-Flex'>
                    <a href="https://apps.apple.com/us/app/gigloca/id6504925922"><img className="Main-Options-App" src={AppStore} alt='Download on the App Store' /></a>
                    <a href="https://play.google.com/store/apps/details?id=com.gigloca&hl=en"><img className="Main-Options-App" src={PlayStore} alt='Get it on Google Play' /></a>
                </div>
            </div>
            <div className='Main-Footer'>
                <LinkContainer to={{ pathname: '/login', search: 'page=privacy' }} search="page=privacy"><div className='Main-Legal'>Change Password</div></LinkContainer>
                <LinkContainer to={{ pathname: '/legal', search: 'page=privacy' }} search="page=privacy"><div className='Main-Legal'>Privacy, Data and Use</div></LinkContainer>
            </div>
        </div>
    }
}

export default Main