import { Component } from 'react'
import { API } from 'aws-amplify'
import moment from 'moment'
import withRouter from '../components/withRouter'
import Card from '../components/card'
import TopMenu from '../components/topMenu'
import BottomMenu from '../components/bottomMenu'
import Logo from '../images/GigLoca_Logo_Light.png'
import Form from "react-bootstrap/Form"
import { TbMapPinSearch, TbCalendarPin } from 'react-icons/tb'
import { MdOutlineGroupAdd } from 'react-icons/md'
import { FaConnectdevelop } from 'react-icons/fa6'
import './node.css'

class MyNodes extends Component {
    constructor(props) {
        super(props)

        this.state = {
            bounce: false,
            displaySettings: false,
            node: '',
            menu: {
                left: 'home',
                right: null,
                mode: this.props.mode,
                action: 'addNode'
            },
            data: null,
            next: null,
            nexts: [],
            view: 'list',
            splash: false,
            logo: true,
            msg: '',
            displayMsg: false,
            emailConsent: false
        }

        this.handleOnPrefSelect = this.handleOnPrefSelect.bind(this)
        this.handleDisplaySettings = this.handleDisplaySettings.bind(this)
        this.handleModeChange = this.handleModeChange.bind(this)
        this.handleOnNotificationsSelect = this.handleOnNotificationsSelect.bind(this)
        this.handleGoToCard = this.handleGoToCard.bind(this)
        this.handleGiglit = this.handleGiglit.bind(this)
        this.handleHome = this.handleHome.bind(this)
    }

    componentDidMount() {
        if (this.props.isAuthenticated && this.props.user && this.props.user.active) {
            const { search } = this.props
            let mode = search.pathname.toLowerCase() === '/my/venues' ? 'Venue' : (search.pathname.toLowerCase() === '/my/contributors' ? 'Contributor' : this.props.mode)
            if (mode) this.props.setMode(mode)
            if (mode && this.props.user.dyk && this.props.user.dyk[mode.toLowerCase()]) {
                this.handleDYK()
            } else {
                this.setState({ menu: { ...this.state.menu, mode }, emailConsent: this.props.user.emailConsent || false })
                API.get('lt', `my?start=${moment().date(1).format('YYYY-MM-DD')}&stop=${moment().date(1).add(1, 'month').format('YYYY-MM-DD')}&next=${this.state.next}`)
                    .then(resp => {
                        if (Object.keys(resp.data).length > 0) {
                            let nexts = []
                            if (resp.numKeys && resp.numKeys > 1000) {
                                nexts.push(0)
                                let pages = parseInt(resp.numKeys / 1000)
                                for (let i = 0; i < pages; i++) {
                                    nexts.push(i + 1)
                                }
                            }
                            this.setState({ data: resp.data || [], next: resp.next || null, nexts, logo: false, splash: ((mode === 'Venue' && (resp.data.Venue || []).length === 0)) || (mode !== 'Venue' && Object.keys(resp.data).filter(d => d !== 'Venue').length === 0) })
                            this.props.setMy(resp.data)
                        } else this.setState({ splash: true, logo: false })
                    })
                    .catch(err => {
                        console.log(err)
                        if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
                    })
            }
        } else {
            this.setState({ bounce: true, logo: false })
            setTimeout(() => this.handleNavigate('main', true), 3500)
        }
    }

    handleDYK() {
        setTimeout(() => this.handleNavigate(`DYK`, true), 150)
    }

    handleNavigate(page, history) {
        if (history) this.props.onAddHistory(`my/${this.props.mode}s`)
        const { navigate } = this.props
        navigate(`/${page}`)
    }

    handleHome() {
        this.handleNavigate('', true)
    }

    handleOnPrefSelect() {
        this.handleNavigate('profile', true)
    }

    handleDisplaySettings() {
        this.setState({ displaySearch: false, displaySettings: !this.state.displaySettings })
    }

    handleModeChange(mode) {
        if (mode) {
            this.props.setMode(mode)
            if (mode === 'contactus') {
                this.handleNavigate('contactus')
            } else if (mode === 'tix') {
                this.handleNavigate('tixcheck')
            } else if (mode === 'fan') {
                this.handleNavigate('', true)
            } else {
                this.handleNavigate(`my/${mode}s`)
                this.props.setMode(mode)
                this.setState({
                    displaySettings: false,
                    menu: {
                        left: null,
                        right: null,
                        mode: mode,
                        action: 'addNode'
                    },
                    splash: (this.state.data ? ((mode === 'Venue' && (this.state.data.Venue || []).length === 0)) || (mode !== 'Venue' && Object.keys(this.state.data).filter(d => d !== 'Venue').length === 0) : true)
                })
            }
        } else this.setState({ displaySettings: false })
    }

    handleOnNotificationsSelect() {
        this.handleNavigate('notifications', true)
    }

    handleGoToCard(nid, type) {
        if (nid && type) {
            this.handleNavigate(type === 'Venue' ? `my/venue/${nid}` : `my/contributor/${nid}`, true)
        }
    }

    handleGiglit() {
        this.handleNavigate(`my/${this.props.mode}/new`)
    }

    handleCheckChanged(e) {
        this.setState({ [e.target.value]: e.target.checked })
        const body = { emailConsent: e.target.checked }
        API.put('lt', 'user', { body })
            .then(resp => {
                this.props.setUser({ ...this.props.user, ...body })
            })
            .catch(e => {
                this.setState({ msg: e.response && e.response.data && e.response.data.error ? e.response.data.error : e.message, displayMsg: true })
                console.log(e)
            })
    }

    handleMsgOK() {
        this.setState({ msg: '', displayMsg: false })
    }

    handlePageChange(event) {
        this.setState({ next: event.target.value })
    }

    handleNext() {
        if (this.state.next) {
            API.get('lt', `my?start=${moment().date(1).format('YYYY-MM-DD')}&stop=${moment().date(1).add(1, 'month').format('YYYY-MM-DD')}&next=${this.state.next}`)
                .then(resp => {
                    if (Object.keys(resp.data).length > 0) {
                        this.setState({ data: resp.data || [], next: resp.next || null })
                        this.props.setMy(resp.data)
                    } else this.setState({ splash: true, logo: false })
                })
                .catch(err => {
                    console.log(err)
                    if (err.response && err.response.status && err.response.status === 403) this.handleNavigate('main')
                })
        }
    }

    getLogo() {
        return <div className='My-Splash'>
            <img src={Logo} className='Node-Logo' alt="Gigloca" />
        </div>
    }

    getSplash() {
        return <div className='My-Splash'>
            <img src={Logo} className='Node-Logo' alt="Gigloca" />
            {
                this.props.mode === 'Venue' ? <div className='Node-Row'>
                    <b><u>Add Your Venue to GigLoca</u></b><br /><br />
                    <div className='Node-Add-Header'><span style={{ marginRight: '5px' }}><TbMapPinSearch /></span>Enhance Visibility</div>
                    <div className='Node-Add-Text'>Expand your event's reach.</div>
                    <div className='Node-Add-Header'><span style={{ marginRight: '5px' }}><MdOutlineGroupAdd /></span>Broaden Your Audience</div>
                    <div className='Node-Add-Text'>Attract new attendees and engage loyal supporters.</div>
                    <div className='Node-Add-Header'><span style={{ marginRight: '5px' }}><TbCalendarPin /></span>Broadcast Your Schedule</div>
                    <div className='Node-Add-Text'>Easily showcase your upcoming events.</div>
                    <div className='Node-Add-Header'><span style={{ marginRight: '5px' }}><FaConnectdevelop /></span>Inspire Collaborations</div>
                    <div className='Node-Add-Text'>Enable venues to find dynamic event collaborators.</div>
                </div> : <div className='Node-Row'>
                    <b><u>Add Your Talents to GigLoca</u></b><br /><br />
                    <div className='Node-Add-Header'><span style={{ marginRight: '5px' }}><TbMapPinSearch /></span>Enhance Visibility</div>
                    <div className='Node-Add-Text'>Reach more people with your talents.</div>
                    <div className='Node-Add-Header'><span style={{ marginRight: '5px' }}><MdOutlineGroupAdd /></span>Broaden Your Audience</div>
                    <div className='Node-Add-Text'>Engage with diverse crowds.</div>
                    <div className='Node-Add-Header'><span style={{ marginRight: '5px' }}><TbCalendarPin /></span>Broadcast Your Schedule</div>
                    <div className='Node-Add-Text'>Promote your events and availability effortlessly.</div>
                    <div className='Node-Add-Header'><span style={{ marginRight: '5px' }}><FaConnectdevelop /></span>Connect with Venues</div>
                    <div className='Node-Add-Text'>Find opportunities to showcase your talents.</div>
                </div>
            }
        </div>
    }

    getBounce() {
        return <div className='Node-Content'>
            <img src={Logo} className='Node-Logo' alt="Gigloca" />
            <div className='Pref-Bounce'>For your All-Access Pass to all that Gigloca has to offer, create your account or sign in to Gigloca.</div>
        </div>
    }

    getMessage() {
        return <div className='Node-Confirm'>
            <div className='Node-Row Confirm-Text'>{this.state.msg}</div>
            <div className="Node-Row Node-Flex-Wrap">
                <div className='Pref-Button Pref-Button-Save' onClick={() => this.handleMsgOK()}>OK</div>
            </div>
        </div>
    }

    getList() {
        return <div className='Node-Cards'>
            <div className="Node-Row">
                <div className='Signup-Checkbox'>
                    <input value="emailConsent" type="checkbox" checked={this.state.emailConsent} onChange={event => this.handleCheckChanged(event)} />
                    <span className='Signup-Checkbox-Text'>I consent to receive Gigloca Activity Emails (regarding Management for Venues, Events and Contributors)</span>
                </div>
            </div>
            {
                this.state.data && this.props.mode === 'Venue' && this.state.data.Venue ? <div className='Node-Flex-Wrap'>{this.state.data.Venue.map((v, vidx) => {
                    return <div key={`venue-${vidx}`} style={{ margin: '5px 0' }}><Card data={v} type='venue' onEdit={this.handleGoToCard} /></div>
                })}</div> : null
            }
            {
                this.state.data && this.props.mode !== 'Venue' ? Object.keys(this.state.data).filter(key => key !== 'Venue').map((nt, ntidx) => {
                    return <div key={`nt-${ntidx}`}>
                        <div className='Node-Row Node-Flex-Left'>
                            <div className='Node-Divider-Text'>{nt}</div>
                            <div className='Node-Divider'></div>
                        </div>
                        <div className='Node-Flex-Wrap'>{this.state.data[nt].map((n, nidx) => {
                            return <div key={`node-${nidx}`} style={{ margin: '5px 0' }}><Card data={n} type='contributor' onEdit={this.handleGoToCard} /></div>
                        })}</div>
                    </div>
                }) : null
            }
            {
                this.state.nexts.length > 0 ? <div className="Node-Row" style={{ marginTop: '10px' }}>
                    <div className='Node-Row'>
                        <Form.Group className="form-group form-group-lg">
                            <Form.Label className='Pref-Label' style={{ width: '100%', backgroundColor: '#6e4ae0', borderRadius: '5px', paddingLeft: '5px' }}>Page</Form.Label>
                        </Form.Group>
                    </div>
                    <div className='Node-Flex-Wrap'>
                        <Form.Select style={{ color: '#190753', borderRadius: '5px', fontSize: '14pt', padding: '5px' }} value={this.state.next} id={`page`} onChange={(event) => this.handlePageChange(event)}>
                            <option value=''>Select...</option>
                            {
                                this.state.nexts.map((o, oidx) => {
                                    return <option key={`nt-${oidx}`} value={o}>Page {o}</option>
                                })
                            }
                        </Form.Select>
                        <div className='Pref-Button Pref-Button-Save' onClick={() => this.handleNext()}>{`Fetch Page`}</div>
                    </div>
                </div> : null
            }
        </div>
    }

    render() {
        //console.log(this.state, this.props)
        return <div className={`My-Container Node-${this.props.mode}`}>
            <TopMenu
                mode={this.props.mode}
                onMode={this.handleModeChange}
                onPref={this.handleOnPrefSelect}
                notifications={this.props.user ? (this.props.user.notifications && this.props.user.notifications.length > 0 ? this.props.user.notifications.length : true) : null}
                onNotifications={this.handleOnNotificationsSelect}
                user={this.props.user ? { firstName: this.props.user.firstName, image: this.props.user.image } : null}
                onSettings={this.handleDisplaySettings}
                pref={null}
                displaySettings={this.state.displaySettings} />
            {
                this.state.bounce ? this.getBounce() : (this.state.logo ? this.getLogo() : (this.state.splash ? this.getSplash() : this.getList()))
            }
            <BottomMenu
                menu={this.state.menu}
                onLeft={this.handleHome}
                onGiglit={this.handleGiglit}
                onRight={this.handleRightMenu} />
        </div>
    }
}

export default withRouter(MyNodes)