import { Component } from 'react'
import { Auth, API } from 'aws-amplify'
import withRouter from './components/withRouter'
import Routes from './routes'
import './App.css'
import Logo from './images/GigLoca_Logo_Light.png'
import AppStore from './images/appstore.svg'
import PlayStore from './images/playstore.png'

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isAuthenticated: false,
      isLoadingBackground: true,
      isGiglocaScreen: false,
      user: null,
      loadingCss: 'App-Loading-Bar-Init',
      loadedCss: 'App-Faded',
      bgImg: 'Gigloca-Image-Start',
      back: [],
      myLoc: null,
      data: null,
      mode: 'fan',
      myNodes: null,
      filter: null,
      target: null
    }

    this.handleLogout = this.handleLogout.bind(this)
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser()
      .then((resp) => {
        this.setState({ isAuthenticated: true })
        API.get('lt', 'user')
          .then(resp => {
            this.setState({
              loadingCss: 'App-Loading-Bar-Final',
              user: resp.data || null
            })
          })
          .catch(err => {
            //console.log(err)
            this.setState({ isAuthenticated: false, user: null })
            const { navigate } = this.props
            navigate('/main')
          })
      })
      .catch(e => {
        console.log(e)
        this.setState({ isAuthenticated: false, user: null })
        //const { navigate } = this.props
        //navigate('/main')
      })
    setTimeout(() => this.setState({ loadingCss: 'App-Loading-Bar-Post', isGiglocaScreen: true }), 230)
    setTimeout(() => this.setState({ isLoadingBackground: false, loadedCss: 'App-Showing' }), 500)
    setTimeout(() => this.setState({ bgImg: 'Gigloca-Image-End' }), 700)
    setTimeout(() => this.setState({ isGiglocaScreen: false }), 3000)
  }

  userHasAuthenticated = authenticated => this.setState({ isAuthenticated: authenticated, isGiglocaScreen: false })
  setUser = user => this.setState({ user })
  onBack = (refresh) => this.handleBack(refresh)
  addHistory = path => this.handleAddHistory(path)
  onMyLoc = myLoc => this.setState({ myLoc })
  setData = data => this.setState({ data })
  setMode = mode => this.setState({ mode })
  setMy = myNodes => this.setState({ myNodes })
  setFilter = filter => this.setState({ filter })

  async handleLogout() {
    if (this.state.isAuthenticated) {
      try {
        await Auth.signOut({ global: true })
        this.setState({ user: null, data: null })
        this.userHasAuthenticated(false)
        const { navigate } = this.props
        navigate('/')
      } catch (e) {
        console.log(e.message)
      }
    }
  }

  handleGiglocaScreen() {
    this.setState({ isGiglocaScreen: false })
  }

  handleAddHistory(path) {
    let back = [...this.state.back]
    back.push(path)
    this.setState({ back })
  }

  handleBack(refresh) {
    //stack 'back' values LIFO
    const { navigate } = this.props
    let back = this.state.back ? [...this.state.back] : []
    let goto = back.length > 0 ? back.splice(back.length - 1, 1) : ['/']
    this.setState({ back })
    navigate(`${goto[0]}${refresh ? '?refresh=true' : ''}`)
  }

  handleApp(app) {
    if (app === 'apple') {
        window.open(`https://apps.apple.com/us/app/gigloca/id6504925922`, '_blank', 'noopener')
    } else if (app === 'play') {
        window.open(`https://play.google.com/store/apps/details?id=com.gigloca&hl=en`, '_blank', 'noopener')
    }
}

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      setUser: this.setUser,
      user: this.state.user,
      onBack: this.onBack,
      onAddHistory: this.addHistory,
      onLogout: this.handleLogout,
      onMyLoc: this.onMyLoc,
      myLoc: this.state.myLoc,
      data: this.state.data,
      setData: this.setData,
      setMode: this.setMode,
      mode: this.state.mode,
      setMy: this.setMy,
      myNodes: this.state.myNodes,
      setFilter: this.setFilter,
      filter: this.state.filter
    }
    //console.log(this.state)
    return (
      !this.state.isLoadingBackground ?
        <div>
          {
            this.state.isGiglocaScreen ? <div className={`Gigloca-Container ${this.state.bg}`}>
              <div className='Gigloca-Title' onClick={() => this.handleGiglocaScreen()}><img src={Logo} className={`Gigloca-Image ${this.state.bgImg}`} alt='Gigloca' /></div>
              <div className='Gigloca-Text-1'></div>
              <div className='Gigloca-Row Gigloca-Flex-Spread'>
                    <a href="https://itunes.apple.com/us/app/"><img className="Gigloca-Button-App" src={AppStore} alt='Download on the App Store' /></a>
                    <a href="https://play.google.com/store/apps/details?id=com.gigloca&hl=en"><img className="Gigloca-Button-App" src={PlayStore} alt='Get it on Google Play' /></a>
                </div>
            </div> :
              <Routes childProps={childProps} />
          }
        </div> :
        <div className="App-Loading">
          <div className={`App-Loading-Bar ${this.state.loadingCss}`}></div>
        </div>
    )
  }
}

export default withRouter(App);
